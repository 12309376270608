var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar',{staticClass:"fixed-left",attrs:{"toggleable":"md","type":"dark","id":"navbar"}},[_c('b-navbar-nav',{staticClass:"justify-content-center"},[_c('b-nav-item',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
        placement: 'left',
        variant: 'dark',
        boundary: 'viewport',
      }),expression:"{\n        placement: 'left',\n        variant: 'dark',\n        boundary: 'viewport',\n      }"}],staticClass:"text-center",staticStyle:{"margin-top":"-6px"},attrs:{"title":"Accueil"}},[_c('b-navbar-brand',{staticClass:"mr-0",attrs:{"to":"/"}},[_c('img',{attrs:{"src":_vm.publicPath + 'img/logo.png',"height":"50"}})])],1),_c('b-nav-item',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
        placement: 'left',
        variant: 'dark',
        boundary: 'viewport',
      }),expression:"{\n        placement: 'left',\n        variant: 'dark',\n        boundary: 'viewport',\n      }"}],staticClass:"text-center px-3 px-md-0",attrs:{"to":"equipe","title":"Équipe"}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'hat-wizard']}})],1),_c('b-nav-item',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
        placement: 'left',
        variant: 'dark',
        boundary: 'viewport',
      }),expression:"{\n        placement: 'left',\n        variant: 'dark',\n        boundary: 'viewport',\n      }"}],staticClass:"text-center px-3 px-md-0",attrs:{"to":"grimoire","title":"Grimoire"}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'book']}})],1),_c('b-nav-item',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
        placement: 'left',
        variant: 'dark',
        boundary: 'viewport',
      }),expression:"{\n        placement: 'left',\n        variant: 'dark',\n        boundary: 'viewport',\n      }"}],staticClass:"text-center px-3 px-md-0",attrs:{"to":"festivites","title":"Festivités"}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'moon']}})],1),_c('b-nav-item',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
        placement: 'left',
        variant: 'dark',
        boundary: 'viewport',
      }),expression:"{\n        placement: 'left',\n        variant: 'dark',\n        boundary: 'viewport',\n      }"}],staticClass:"text-center px-3 px-md-0",attrs:{"target":"_blank","href":"https://sorcie.re/walpurgis","title":"Pleroma"}},[_c('img',{staticClass:"svg-inline--fa fa-w-14 brand",attrs:{"src":require("../assets/img/brand/pleroma.svg")}})]),_c('b-nav-item',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
        placement: 'left',
        variant: 'dark',
        boundary: 'viewport',
      }),expression:"{\n        placement: 'left',\n        variant: 'dark',\n        boundary: 'viewport',\n      }"}],staticClass:"text-center px-3 px-md-0",attrs:{"target":"_blank","href":"https://matrix.to/#/+walpurgis:iiens.net","title":"Matrix"}},[_c('img',{staticClass:"svg-inline--fa fa-w-14 brand",attrs:{"src":require("../assets/img/brand/element.svg")}})]),_c('b-nav-item',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
        placement: 'left',
        variant: 'dark',
        boundary: 'viewport',
      }),expression:"{\n        placement: 'left',\n        variant: 'dark',\n        boundary: 'viewport',\n      }"}],staticClass:"text-center px-3 px-md-0",attrs:{"target":"_blank","href":"https://discord.gg/Pazx5WDACc","title":"Discord"}},[_c('font-awesome-icon',{attrs:{"icon":['fab', 'discord']}})],1),_c('b-nav-item',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
        placement: 'left',
        variant: 'dark',
        boundary: 'viewport',
      }),expression:"{\n        placement: 'left',\n        variant: 'dark',\n        boundary: 'viewport',\n      }"}],staticClass:"text-center px-3 px-md-0",attrs:{"target":"_blank","href":"mailto:walpurgis@listes.iiens.net","title":"Mail"}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'envelope']}})],1),_c('player',{attrs:{"playlist":[
        (_vm.publicPath + "audio/Conturbatio.ogg"),
        (_vm.publicPath + "audio/Decretum.ogg"),
        (_vm.publicPath + "audio/Symposium magarum.ogg"),
        (_vm.publicPath + "audio/Serena ira.ogg") ],"autoPlay":false}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }